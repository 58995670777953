import { ISortDirection } from "interfaces/service.interface";
import { useCallback, useEffect, useState } from "react";
import { classNameJoin } from "utils/lib";
import ResizeLine from "./ResizeLine";
import SortArrow from "./SortArrow";

interface IProps {
  columns: {
    text: string;
    ref: React.MutableRefObject<HTMLTableHeaderCellElement | null>;
  }[];
  align: string[];
  sort: boolean[];
  column: string[];
  sortDirection?: ISortDirection;
  sortBys?: { [key: string]: ISortDirection };
  width: (string | number)[];
  tableHeight: number | string;
  tableRef: React.MutableRefObject<HTMLTableElement | null>;
  onSort: (i: number, d: ISortDirection) => void;
}

const THead = ({
  columns,
  tableHeight,
  align,
  sort,
  column,
  sortDirection = "ASC",
  sortBys,
  width,
  tableRef,
  onSort,
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [pageX, setPageX] = useState<number>(0);
  const [curColWidth, setCulColWidth] = useState<number>(0);
  const [nxtColWidth, setNxtColWidth] = useState<number | null>(null);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: ISortDirection;
  }>(sortBys || {});

  const sortHandler = (index: number) => {
    if (!sort[index]) return;

    let dr: ISortDirection = null;

    switch (sortDirections[column[index]]) {
      case "ASC":
        if (sortDirection === "DESC") {
          dr = null;
        } else {
          dr = "DESC";
        }
        break;
      case "DESC":
        if (sortDirection === "DESC") {
          dr = "ASC";
        } else {
          dr = null;
        }
        break;
      case null:
        if (sortDirection === "DESC") {
          dr = "DESC";
        } else {
          dr = "ASC";
        }
        break;
      default:
        dr = sortDirection;
    }

    setSortDirections({ [column[index]]: dr });
    onSort(index, dr);
  };

  const mouseDownHandler = (
    ev: React.MouseEvent<HTMLDivElement>,
    index: number | null
  ) => {
    const div = ev.target as HTMLDivElement;
    const culCol = div.parentElement;

    if (!culCol) return;

    setActiveIndex(index);
    setPageX(ev.pageX);
    setCulColWidth(culCol.offsetWidth);

    if (culCol.nextElementSibling) {
      setNxtColWidth((culCol.nextElementSibling as HTMLElement).offsetWidth);
    }

    if (tableRef.current) {
      tableRef.current.style.setProperty("user-select", "none");
    }
  };

  const mouseMoveHandler = useCallback(
    (ev: MouseEvent) => {
      const index = columns.findIndex((_, i) => i === activeIndex);

      if (index !== -1) {
        const col = columns[index].ref.current;

        if (col) {
          const nextCol = col.nextElementSibling as HTMLElement;
          const width = ev.pageX - pageX;

          if (nextCol && nxtColWidth) {
            nextCol.style.width = `${nxtColWidth - width}px`;
          }

          col.style.width = `${curColWidth! + width}px`;
        }
      }
    },
    [activeIndex, columns, curColWidth, nxtColWidth, pageX]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMoveHandler);
    window.removeEventListener("mouseup", removeListeners);

    if (tableRef.current) {
      tableRef.current.style.removeProperty("user-select");
    }
  }, [mouseMoveHandler, tableRef]);

  const mouseUpHandler = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", mouseUpHandler);
    }
  }, [activeIndex, mouseMoveHandler, mouseUpHandler]);

  useEffect(() => {
    if (sortBys) {
      setSortDirections(sortBys);
    }
  }, [sortBys]);

  return (
    <thead className="sticky top-0 select-none text-sm">
      <tr className="h-8">
        {columns.map(({ text, ref }, i) => (
          <th
            ref={ref}
            key={i}
            style={{ width: width[i] }}
            className={classNameJoin([
              "relative font-normal py-1 px-3 whitespace-nowrap text-xs text-neutral-600 bg-neutral-100",
              align[i],
              sort[i] ? "cursor-pointer select-none" : "",
            ])}
            onMouseDown={() => sortHandler(i)}
          >
            <span className={sort[i] ? "mr-2" : ""}>{text}</span>
            <SortArrow sort={sort[i]} direction={sortDirections[column[i]]} />

            <ResizeLine
              tableHeight={tableHeight}
              onMouseDown={(ev) => {
                ev.stopPropagation();
                mouseDownHandler(ev, i);
              }}
            />
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
