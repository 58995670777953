import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ConfirmProvider from "contexts/ConfirmProvider";

import App from "./App";

import "react-datepicker/dist/react-datepicker.css";
import "styles/global.css";
import "config/icon";
import ToastProvider from "contexts/ToastProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ConfirmProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </ConfirmProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>
);
