import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import GlobalNavigationBar from "layouts/GlobalNavigationBar";
import routes from ".";

const Fallback = () => <div />;

const Home = () => {
  const pages = useRoutes(routes);

  return (
    <>
      {/* <GlobalHeader /> */}
      <div className="relative h-full flex overflow-hidden">
        <GlobalNavigationBar />
        <Suspense fallback={<Fallback />}>{pages}</Suspense>
      </div>
    </>
  );
};

export default Home;
