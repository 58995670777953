import { classNameJoin } from "utils/lib";
import Icon from "../Icon";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onUpload: (files: FileList | null) => void;
}

const Button = ({ onUpload, className, ...props }: IProps) => {
  const uploadHandler = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(ev.target.files);
    ev.target.value = "";
  };

  return (
    <label
      className={classNameJoin([
        "flex items-center justify-center w-16 h-16 border rounded-xl cursor-pointer border-secondary-200 text-secondary-600 bg-white",
        className,
      ])}
    >
      <Icon icon="plus" fontSize={18} />
      <input
        className="hidden"
        type="file"
        onChange={uploadHandler}
        {...props}
      />
    </label>
  );
};

export default Button;
