import DatePicker, { registerLocale, DatePickerProps } from "react-datepicker";
import ko from "date-fns/locale/ko";
import React, { useState } from "react";
import { classNameJoin } from "utils/lib";

registerLocale("ko", ko);

interface IProps {
  small?: boolean;
  placeholder?: string;
}

const Calendar = React.forwardRef<DatePicker, IProps & DatePickerProps>(
  (
    {
      className,
      small,
      selected,
      placeholder,
      ...props
    }: IProps & DatePickerProps,
    ref
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
    };

    const blurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false);
    };

    return (
      <DatePicker
        ref={ref}
        {...props}
        className={classNameJoin([
          "border px-3 outline-none rounded-md relative z-40 w-full",
          className,
          small ? "h-[32px]" : "h-[40px]",
          focus ? "border-primary-900" : "border-secondary-200",
        ])}
        selected={selected}
        placeholderText={placeholder}
        locale="ko"
        dateFormat="yyyy.MM.dd"
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
    );
  }
);

Calendar.displayName = "Calendar";

export default Calendar;
