import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowRotateLeft,
  faArrowRightFromBracket,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faCheck,
  faCheckCircle,
  faCircleExclamation,
  faChevronLeft,
  faChevronRight,
  faCog,
  faDashboard,
  faFilter,
  faImage,
  faPen,
  faPlay,
  faPlus,
  faSackDollar,
  faSearch,
  faTrash,
  faTrashAlt,
  faUsers,
  faXmark,
  faChalkboard,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendar,
  faCreditCard,
  faTrashAlt as farTrashAlt,
} from "@fortawesome/free-regular-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faCheck,
  faCheckCircle,
  faCircleExclamation,
  faChevronLeft,
  faChevronRight,
  faCog,
  faCreditCard,
  faDashboard,
  faFilter,
  faImage,
  faPen,
  faPlay,
  faPlus,
  faSackDollar,
  faSearch,
  faTrash,
  faTrashAlt,
  farTrashAlt,
  faUsers,
  faXmark,
  faChalkboard
);
