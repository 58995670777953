import DatePicker, {
  registerLocale,
  DatePickerProps,
  CalendarContainer,
} from "react-datepicker";
import ko from "date-fns/locale/ko";
import React, { useState } from "react";
import { classNameJoin } from "utils/lib";
import dayjs from "dayjs";

registerLocale("ko", ko);

interface IProps {
  small?: boolean;
  placeholder?: string;
  customSelectedDates?: (Date | null)[];
}

const CalendarV2 = React.forwardRef<DatePicker, IProps & DatePickerProps>(
  (
    {
      className,
      small,
      selected,
      placeholder,
      customSelectedDates,
      ...props
    }: IProps & DatePickerProps,
    ref
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    const focusHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
    };

    const blurHandler = (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false);
    };

    return (
      <DatePicker
        ref={ref}
        className={classNameJoin([
          "border px-3 outline-none rounded-md relative z-40 w-full",
          className,
          small ? "h-[32px]" : "h-[40px]",
          focus ? "border-primary-900" : "border-secondary-200",
        ])}
        showTimeSelect
        timeIntervals={60}
        timeCaption=""
        shouldCloseOnSelect={false}
        selected={selected}
        placeholderText={placeholder}
        locale="ko"
        dateFormat="yyyy.MM.dd HH:mm"
        dayClassName={(date) => {
          if (!customSelectedDates) return "";

          const index = customSelectedDates.findIndex((d) => {
            if (d === null) return false;
            return dayjs(d).isSame(date);
          });

          if (index !== -1) return "selected";
          return "";
        }}
        calendarContainer={CalendarV2Container}
        onFocus={focusHandler}
        onBlur={blurHandler}
        {...props}
      />
    );
  }
);

CalendarV2.displayName = "CalendarV2";

const CalendarV2Container = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className="p-4 border rounded-lg bg-white border-[#aeaeae]">
      <CalendarContainer className={classNameJoin(["border-none", className])}>
        {children}
      </CalendarContainer>
    </div>
  );
};

export default CalendarV2;
