import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

// dashboard
const DashboardPage = React.lazy(() => import("./dashboard/DashboardPage"));

// sales
const SalesTabPage = React.lazy(() => import("./sales/point/TabPage"));
const SalesHistoryPage = React.lazy(() => import("./sales/point/HistoryPage"));
const SalesUsagePage = React.lazy(() => import("./sales/point/UsagePage"));
const SalesLiveListPage = React.lazy(() => import("./sales/live/ListPage"));
const SalesLiveViewPage = React.lazy(() => import("./sales/live/ViewPage"));

// user
const UserSellerListPage = React.lazy(() => import("./user/seller/ListPage"));
const UserSellerViewPage = React.lazy(() => import("./user/seller/ViewPage"));
const UserGuestListPage = React.lazy(() => import("./user/guest/ListPage"));
const UserGuestViewPage = React.lazy(() => import("./user/guest/ViewPage"));
const UserReportListPage = React.lazy(() => import("./user/report/ListPage"));
const UserReportViewPage = React.lazy(() => import("./user/report/ViewPage"));
const UserApprovalPage = React.lazy(() => import("./user/ApprovalPage"));

// config
const ConfigLivePage = React.lazy(() => import("./config/live/LivePage"));
const ConfigLiveHotTimePage = React.lazy(
  () => import("./config/live/HotTimePage")
);
const ConfigLiveLivePricePage = React.lazy(
  () => import("./config/live/LivePricePage")
);
const ConfigPointPage = React.lazy(() => import("./config/point/PointPage"));
const ConfigPointPricePage = React.lazy(
  () => import("./config/point/PointPricePage")
);
const ConfigCategoryPage = React.lazy(() => import("./config/CategoryPage"));

// schedule
const ScheduleListPage = React.lazy(() => import("./schedule/ListPage"));
const ScheduleViewPage = React.lazy(() => import("./schedule/ViewPage"));

// boards
const BoardsGuestListPage = React.lazy(() => import("./boards/guest/ListPage"));
const BoardsGuestEditPage = React.lazy(() => import("./boards/guest/EditPage"));

// notice
const NoticeListPage = React.lazy(() => import("./boards/notice/ListPage"));
const NoticeEditPage = React.lazy(() => import("./boards/notice/EditPage"));

export const LoginPage = React.lazy(() => import("./LoginPage"));
export const LogoutPage = React.lazy(() => import("./LogoutPage"));

const routes: RouteObject[] = [
  {
    path: "dashboard",
    element: <Outlet />,
    children: [{ index: true, element: <DashboardPage /> }],
  },
  {
    path: "sales",
    element: <Outlet />,
    children: [
      {
        path: "point",
        element: <SalesTabPage />,
        children: [
          { path: "history", element: <SalesHistoryPage /> },
          { path: "usage", element: <SalesUsagePage /> },
        ],
      },
      { path: "live", element: <SalesLiveListPage /> },
      { path: "live/:id", element: <SalesLiveViewPage /> },
    ],
  },
  {
    path: "user",
    element: <Outlet />,
    children: [
      { path: "seller", element: <UserSellerListPage /> },
      { path: "seller/:id", element: <UserSellerViewPage /> },
      { path: "guest", element: <UserGuestListPage /> },
      { path: "guest/:id", element: <UserGuestViewPage /> },
      { path: "approval", element: <UserApprovalPage /> },
      { path: "report", element: <UserReportListPage /> },
      { path: "report/:id", element: <UserReportViewPage /> },
    ],
  },
  {
    path: "config",
    element: <Outlet />,
    children: [
      { path: "live", element: <ConfigLivePage /> },
      { path: "live/hot", element: <ConfigLiveHotTimePage /> },
      { path: "live/price", element: <ConfigLiveLivePricePage /> },
      { path: "point", element: <ConfigPointPage /> },
      { path: "point/price", element: <ConfigPointPricePage /> },
      { path: "category", element: <ConfigCategoryPage /> },
    ],
  },
  {
    path: "boards",
    element: <Outlet />,
    children: [
      { path: "notice", element: <NoticeListPage /> },
      { path: "notice/edit", element: <NoticeEditPage /> },
      { path: "notice/edit/:id", element: <NoticeEditPage /> },
      //
      { path: "guest", element: <BoardsGuestListPage /> },
      { path: "guest/edit", element: <BoardsGuestEditPage /> },
      { path: "guest/edit/:id", element: <BoardsGuestEditPage /> },
    ],
  },
  {
    path: "schedule",
    element: <Outlet />,
    children: [
      { index: true, element: <ScheduleListPage /> },
      { path: ":id", element: <ScheduleViewPage /> },
    ],
  },
];

export default routes;
