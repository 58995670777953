import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface INav {
  path: string;
  basename: string;
  icon: IconProp;
  name: string;
  children?: {
    path: string;
    basename: string;
    name: string;
    active?: string;
  }[];
}

const navs: INav[][] = [
  [
    {
      path: "/dashboard",
      basename: "dashboard",
      icon: "gauge",
      name: "대시보드",
    },
    {
      path: "/sales/point/history",
      basename: "sales",
      icon: ["far", "credit-card"],
      name: "판매내역",
      children: [
        {
          path: "/sales/point/history",
          basename: "point",
          active: "/sales/point",
          name: "포인트 매출",
        },
        {
          path: "/sales/live",
          basename: "live",
          name: "라이브 매출",
        },
      ],
    },
    {
      path: "/user/seller",
      basename: "user",
      icon: "users",
      name: "사용자 관리",
      children: [
        {
          path: "/user/seller",
          basename: "report",
          name: "셀러 목록",
        },
        {
          path: "/user/guest",
          basename: "guest",
          name: "게스트 목록",
        },
        {
          path: "/user/approval",
          basename: "approval",
          name: "가입신청내역",
        },
        {
          path: "/user/report",
          basename: "report",
          name: "신고목록",
        },
      ],
    },
    {
      path: "/config/live",
      basename: "config",
      icon: "cog",
      name: "정책관리",
      children: [
        {
          path: "/config/live",
          basename: "live",
          name: "라이브 가격",
        },
        {
          path: "/config/point",
          basename: "point",
          name: "포인트 가격",
        },
        {
          path: "/config/category",
          basename: "category",
          name: "카테고리 관리",
        },
      ],
    },
    {
      path: "/schedule",
      basename: "schedule",
      icon: ["far", "calendar"],
      name: "라이브 일정",
    },
    {
      path: "/boards/notice",
      basename: "boards",
      icon: "chalkboard",
      name: "광고 및 공지 관리",
      children: [
        {
          path: "/boards/notice",
          basename: "notices",
          name: "셀러 어드민",
        },
        {
          path: "/boards/guest",
          basename: "guest-main-app",
          name: "게스트앱 메인 페이지",
        },
      ],
    },
    {
      path: "/logout",
      basename: "logout",
      icon: "arrow-right-from-bracket",
      name: "로그아웃",
    },
  ],
];

export default navs;
