import { IFile } from "interfaces/service.interface";
import { useEffect, useRef } from "react";
import { classNameJoin, hasOwnProperty } from "utils/lib";
import Icon from "../Icon";

interface IProps {
  file: File | IFile;
  onDelete: () => void;
}

export const Item = ({ file, onDelete }: IProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (ev) => {
      if (imageRef.current && ev.target) {
        imageRef.current.src = ev.target.result as string;
      }
    };

    // 클라이언트에서 업로드한 이미지 파일
    if (file instanceof File) {
      reader.readAsDataURL(file);
    }

    // 서버에서 가져온 파일배치
    if (hasOwnProperty(file, "url")) {
      if (imageRef.current) {
        imageRef.current.src = (file as IFile).url;
      }
    }
  }, [file]);

  return (
    <div
      className={classNameJoin([
        "relative w-16 h-16 rounded-xl cursor-pointer overflow-hidden object-contain border border-secondary-200",
        "after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-black after:transition-all",
        "group hover:after:opacity-40",
      ])}
      onClick={onDelete}
    >
      <img className="object-cover w-full h-full" ref={imageRef} alt="" />
      <Icon
        className="group-hover:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 hidden text-white"
        icon={["far", "trash-alt"]}
      />
    </div>
  );
};

export default Item;
