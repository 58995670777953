import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "components/shared";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { classNameJoin } from "utils/lib";

type TToastStatus = "complete" | "error";
interface IToast {
  status?: TToastStatus;
  message: string;
}

interface IProps {
  show: (params: IToast) => void;
}

export const ToastContext = React.createContext<IProps>(null!);

export const useToast = () => {
  const context = useContext(ToastContext);

  const toast = (params: IToast) => {
    context.show(params);
  };

  return { toast };
};

const status: {
  [key in TToastStatus]: {
    icon: IconProp;
    iStyle: string;
    style: string;
  };
} = {
  complete: {
    icon: "check-circle",
    iStyle: "text-green-500",
    style: "bg-neutral-800 text-white",
  },
  error: {
    icon: "circle-exclamation",
    iStyle: "text-red-500",
    style: "bg-red-50 text-red-500",
  },
};

export default function ToastProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const timer = useRef<NodeJS.Timer | null>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast | null>(null);

  // toast 활성화
  const show = useCallback((params: IToast) => {
    // timer 취소
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    setIsShow(true);
    setToast({ ...params });

    timer.current = setTimeout(() => {
      setIsShow(false);
    }, 3000);
  }, []);

  const value = useMemo(() => {
    return {
      show,
    };
  }, [show]);

  return (
    <ToastContext.Provider value={value}>
      <>{children}</>

      <div className="fixed top-0 w-full">
        <div
          className={classNameJoin([
            "absolute left-1/2 -translate-x-1/2 inline-flex items-center p-4 min-w-72 rounded-lg transition-[transform,visibility,opacity]",
            status[toast?.status || "complete"].style,
            isShow
              ? "translate-y-8 opacity-100 visible"
              : "translate-y-0 opacity-0 invisible",
          ])}
        >
          <Icon
            className={status[toast?.status || "complete"].iStyle}
            fontSize={16}
            icon={status[toast?.status || "complete"].icon}
          />
          <span className="ml-2 text-xs">{toast?.message}</span>
        </div>
      </div>
    </ToastContext.Provider>
  );
}
