import { NavLink, useLocation } from "react-router-dom";
import navs from "config/navs";
import { Icon } from "components/shared";
import { classNameJoin } from "utils/lib";

const GlobalNavigationBar = () => {
  const { pathname } = useLocation();
  const domain = pathname.split("/")[1];

  return (
    <nav className="w-[230px] h-full bg-neutral-100">
      <div className="p-4">
        <div className="text-lg font-bold text-primary-900">쇼핑의 여왕</div>
        <div className="text-xs">마스터 관리자</div>
      </div>
      {navs.map((nav, i) => {
        return (
          <ul key={i} className="py-1 px-3">
            {nav.map(({ path, basename, name, icon, children }) => {
              return (
                <li key={path}>
                  <NavLink
                    className={({ isActive }) =>
                      classNameJoin([
                        "flex items-center h-9 px-2 rounded-md",
                        isActive || path.indexOf(domain) !== -1
                          ? " text-primary-800 bg-primary-200 font-medium"
                          : "text-neutral-500",
                      ])
                    }
                    to={path}
                    state={{ globalNav: true }}
                  >
                    <Icon
                      className="w-6 text-center"
                      icon={icon}
                      fontSize={14}
                    />
                    <span className="ml-3 text-sm">{name}</span>
                  </NavLink>

                  {domain === basename && children && (
                    <ul className="py-1.5">
                      {children.map(({ path, name, active }) => (
                        <li key={path}>
                          <NavLink
                            className={({ isActive }) =>
                              classNameJoin([
                                "block pl-12 py-2 text-sm",
                                isActive || (active && pathname.match(active))
                                  ? " text-primary-800"
                                  : "text-neutral-500",
                              ])
                            }
                            to={path}
                            state={{ globalNav: true }}
                            key={path}
                          >
                            {name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        );
      })}
    </nav>
  );
};

export default GlobalNavigationBar;
